import BackgroundContainer from 'components/BackgroundContainer';
import Hand from 'components/Hand';
import Stack from 'components/Stack';
import TurnLabel from 'components/TurnLabel';
import LookUpLabel from 'components/LookUpLabel';
import { useGameContext, usePlayerContext, GamePhase } from 'context/GameContext';
import styled from 'styled-components';
import Controls from 'components/Controls/Controls';
import { useEffect, useState } from 'react';
import { Overlay } from '@magicyard/magicpoker-components';
import ButtonBase from 'components/Controls/Buttons/ButtonBase';
import { useMixpanel } from 'context/MixpanelContext';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100vh;
`;

const NextHandStyledOverlay = styled(Overlay)`
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
  font-family: ${(p) => p.theme.font.info.family};
  font-size: ${(p) => p.theme.font.info.size};
  font-weight: ${(p) => p.theme.font.info.weight};
  opacity: ${(p) => (p.active ? '1' : '0')};
  pointer-events: ${(p) => (p.active ? 'all' : 'none')};
`;

const Main = () => {
  const { ctx, moves, G } = useGameContext();

  const { player, isTurn, playerID } = usePlayerContext();
  const mixpanel = useMixpanel();

  const playerHasMoney = Boolean(player && player.stack > 0);

  const isAnnouncementPhase = ctx.phase === GamePhase.AnnounceWinners;

  const [canStartNextHand, setCanStartNextHand] = useState(false);

  const [canRebuy, setCanRebuy] = useState(false);
  const playerShouldSpeak = ctx.phase === GamePhase.Hand && isTurn;
  const controllerId = player?.controller_id;

  useEffect(() => {
    if (controllerId) {
      mixpanel.identify(controllerId);
    }
  }, [mixpanel, controllerId]);

  useEffect(() => {
    let timeout;

    if (isAnnouncementPhase) {
      timeout = setTimeout(() => setCanStartNextHand(true), G.winnerIDs.length * 5000);
    } else {
      setCanStartNextHand(false);
    }

    return () => clearTimeout(timeout);
  }, [isAnnouncementPhase, playerHasMoney, G.winnerIDs]);

  useEffect(() => {
    let timeout;

    if (isAnnouncementPhase && player?.rebuyAvailable) {
      timeout = setTimeout(() => setCanRebuy(true), G.winnerIDs.length * 5000);
    } else if (player?.rebuyAvailable) {
      setCanRebuy(true);
    } else {
      setCanRebuy(false);
    }

    return () => clearTimeout(timeout);
  }, [isAnnouncementPhase, ctx.activePlayers, playerID, G.winnerIDs, player?.rebuyAvailable]);

  return (
    <>
      <BackgroundContainer>
        {player ? (
          <Container>
            {isAnnouncementPhase && !canStartNextHand ? (
              <LookUpLabel
                css={`
                  margin-top: 10px;
                  margin-bottom: 20px;
                `}
                active={isAnnouncementPhase}
              />
            ) : (
              <Stack amount={player.stack} />
            )}
            <TurnLabel
              css={`
                margin-top: 10px;
                margin-bottom: 20px;
              `}
              active={playerShouldSpeak}
            />
            <Hand />
            <Controls />
          </Container>
        ) : null}
      </BackgroundContainer>
      <NextHandStyledOverlay active={canStartNextHand && playerHasMoney}>
        <ButtonBase color="green" size="large" onClick={() => moves.nextHand(playerID)} label={'Start next hand'} />
      </NextHandStyledOverlay>
      <NextHandStyledOverlay active={canRebuy}>
        <ButtonBase color="yellow" size="large" onClick={() => moves.rebuy(playerID)} label={'Rebuy'} />
      </NextHandStyledOverlay>
    </>
  );
};

export default Main;
