import { FC } from 'react';
import Button, { ButtonBaseProps } from './ButtonBase';

export type HalfPotProps = ButtonBaseProps;

const HalfPot: FC<HalfPotProps> = ({ ...props }) => {
  return <Button label="1/2 pot" {...props} />;
};

export default HalfPot;
