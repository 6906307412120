import styled from 'styled-components';

interface ButtonProps {
  disabled?: boolean;
}

const Button = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 82px;
  padding: ${(p) => p.theme.space.padding.small} ${(p) => p.theme.space.padding.large};
  border: 1px solid ${(p) => p.theme.font.button.color};
  font-family: ${(p) => p.theme.font.button.family};
  font-size: ${(p) => p.theme.font.button.size};
  font-weight: ${(p) => p.theme.font.button.weight};
  line-height: ${(p) => p.theme.font.button.lineHeight};
  color: ${(p) => p.theme.font.button.color};
  text-transform: uppercase;
  cursor: pointer;
  opacity: ${(p) => (p.disabled ? '0.3' : '1')};
  user-select: none;

  ${(p) =>
    !p.disabled &&
    `
    &:active {
      background: rgba(255, 255, 255, 0.5);
    }
  `}
`;

export type { ButtonProps };
export default Button;
