import React from 'react';
import styled from 'styled-components';
import type { FC } from 'react';

const Container = styled.div`
  position: relative;
  border-radius: 50px;
  border: 1px solid #ffffff;
  box-shadow: 0 0 5px 0 #112616 inset;
  padding: 0.5rem 3rem 0.5rem 3rem;
  background-color: #1c5e4c;
  color: white;
  font-size: 2.5rem;
  min-width: 80px;
  display: flex;
  align-items: center;
`;

const Chips = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: -1.8rem;
  width: 3.6rem;
`;

export type NewStackProps = {
  amount: number;
  chipURL: string;
  className?: string;
};

const NewStack: FC<NewStackProps> = ({ amount, chipURL, className = '' }) => {
  return (
    <Container className={className}>
      <Chips src={chipURL} />
      <div>${amount}</div>
    </Container>
  );
};

export default NewStack;
