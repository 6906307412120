import SliderUnstyled from '@mui/core/SliderUnstyled';
import { Dispatch, FC, SetStateAction, useRef } from 'react';
import styled from 'styled-components';
import SliderPlusControl from './SliderPlusControl';
import SliderMinusControl from './SliderMinusControl';
import SliderThumb from './SliderThumb';
import { SliderContext } from './SliderContext';

const StyledSlider = styled(SliderUnstyled)`
  display: block;
  position: relative;
  width: 100%;
  padding: 2px 1px;
  border-radius: 50px;

  & .MuiSlider-rail {
    z-index: 2;
    position: absolute;
    width: 100%;
    background: #1d1d1d;
    height: 10%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    outline: 1px solid #5a5a5a;
    border-radius: 1px;
  }

  & .Mui-disabled {
    pointer-events: none;
  }
`;

const SliderRootContainer = styled.div<{ disabled: boolean }>`
  position: relative;
  min-height: 30px;
  width: 100%;
  background-color: #151515;
  padding: 2px 1px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-top: 0;
  position: relative;

  pointer-events: ${(p) => (p.disabled ? 'none' : 'all')};
  opacity: ${(p) => (p.disabled ? 0.4 : 1)};
`;

const SliderRootMain = styled.div<{ disabled: boolean }>`
  z-index: 1;
  position: absolute;
  bottom: 9px;
  border: ${(p) => (p.disabled ? 'none' : '1px solid white')};
  box-shadow: ${(p) => (p.disabled ? '0 0 10px -1px white' : '0 0 6px 1px white')};
  border-radius: 50px;
  color: white;
  font-size: 12px;
  background-color: #2e2e2e;
  height: 100%;
  width: 100%;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
`;

const SliderRoot = ({ children, className = '', disabled = false }) => {
  return (
    <SliderRootContainer disabled={disabled} className={className}>
      <SliderRootMain disabled={disabled}>
        <SliderMinusControl />
        {children}
        <SliderPlusControl />
      </SliderRootMain>
    </SliderRootContainer>
  );
};

const LONG_PRESS_THRESHOLD = 300;
const LONG_PRESS_KEY_REPEAT = 50;

export type SliderProps = {
  setValue: Dispatch<SetStateAction<number>>;
  value: number;
  step: number;
  min: number;
  max: number;
  className?: string;
  disabled?: boolean;
};

const Slider: FC<SliderProps> = ({ className = '', disabled = false, setValue, value, step, min, max }) => {
  const intervalID = useRef<ReturnType<typeof setInterval> | null>(null);
  const increment = () => {
    setValue((prevValue) => Math.min(prevValue + 5, max));
  };
  const decrement = () => {
    setValue((prevValue) => Math.max(prevValue - 5, min));
  };
  const safeClearInterval = () => {
    if (intervalID.current) {
      clearInterval(intervalID.current);
    }
  };
  const onPlusTouchStart = () => {
    safeClearInterval();
    increment();
  };
  const onPlusTouchEnd = () => {
    safeClearInterval();
  };
  const onPlusLongPress = () => {
    safeClearInterval();
    intervalID.current = setInterval(increment, LONG_PRESS_KEY_REPEAT);
  };
  const onMinusTouchStart = () => {
    safeClearInterval();
    decrement();
  };
  const onMinusTouchEnd = () => {
    safeClearInterval();
  };
  const onMinusLongPress = () => {
    intervalID.current = setInterval(decrement, LONG_PRESS_KEY_REPEAT);
  };
  return (
    <SliderContext.Provider
      value={{
        amount: value,
        onPlusTouchStart,
        onPlusTouchEnd,
        onPlusLongPress,
        onMinusTouchStart,
        onMinusTouchEnd,
        onMinusLongPress,
        longPressThresholdMS: LONG_PRESS_THRESHOLD,
        longPressKeyRepeatMS: LONG_PRESS_KEY_REPEAT,
      }}
    >
      <SliderRoot disabled={disabled} className={className}>
        <StyledSlider
          // valueLabelDisplay={'on'}
          disabled={disabled}
          onChange={(_, val) => setValue(val)}
          step={step}
          min={min}
          max={max}
          value={value}
          components={{ Thumb: SliderThumb }}
        />
      </SliderRoot>
    </SliderContext.Provider>
  );
};

export default Slider;
