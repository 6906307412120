import React from 'react';
import styled from 'styled-components';
import { useSliderContext } from './SliderContext';
import useControlsTouchHandler from './useControlsTouchHandler';

const SliderMinusContainer = styled.div`
  border-right: 1px solid #ffffff;
  font-size: 1.55rem;
  width: 15%;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 20px;
  height: 100%;
`;

const SliderMinusMagnifier = styled.div<{ show: boolean }>`
  position: absolute;
  bottom: 180%;
  color: white;
  background: #2a312f;
  border: 2px solid white;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  visibility: ${(p) => (p.show ? 'visible' : 'hidden')};
  transform: translate3d(20%, 0, 0) scale(2);
`;

const SliderMinusControl: React.FC = () => {
  const { onMinusLongPress, onMinusTouchEnd, onMinusTouchStart, longPressThresholdMS } = useSliderContext();
  const { bind, isTouchStart } = useControlsTouchHandler(
    onMinusTouchStart,
    onMinusTouchEnd,
    onMinusLongPress,
    longPressThresholdMS
  );
  return (
    <SliderMinusContainer {...bind}>
      -<SliderMinusMagnifier show={isTouchStart}>-</SliderMinusMagnifier>
    </SliderMinusContainer>
  );
};

export default SliderMinusControl;
