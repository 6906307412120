import styled from 'styled-components';
import { FC, SyntheticEvent, useState } from 'react';
import { m as motion } from 'framer-motion';

export type Color = 'primary' | 'red' | 'yellow' | 'green' | 'blue';

export type ButtonBaseProps = {
  label?: string;
  leftIcon?: string;
  className?: string;
  color?: Color;
  onClick?: React.EventHandler<SyntheticEvent<Element>>;
  disabled?: boolean;
  size?: 'small' | 'large';
};

const colorResolver = {
  primary: {
    backgroundMain: '#2e2e2e',
    backgroundSecondary: '#151515',
    border: '#ffffff',
    shadow: '#ffffff',
  },
  red: {
    backgroundMain: '#460002',
    backgroundSecondary: '#200000',
    border: '#e70000',
    shadow: '#8b1d19',
  },
  yellow: {
    backgroundMain: '#3c2a00',
    backgroundSecondary: '#1b1200',
    border: '#ffcc00',
    shadow: '#908220',
  },
  green: {
    backgroundMain: '#134000',
    backgroundSecondary: '#031600',
    border: '#00e700',
    shadow: '#117d1c',
  },
  blue: {
    backgroundMain: '#013950',
    backgroundSecondary: '#001820',
    border: '#00b7ff',
    shadow: '#094963',
  },
};

const styledButtonContainerVariants = {
  primary: {
    background: colorResolver['primary'].backgroundSecondary,
  },
  red: {
    background: colorResolver['red'].backgroundSecondary,
  },
  yellow: {
    background: colorResolver['yellow'].backgroundSecondary,
  },
  green: {
    background: colorResolver['green'].backgroundSecondary,
  },
  blue: {
    background: colorResolver['blue'].backgroundSecondary,
  },
};
const StyledButtonContainer = styled(motion.div)<{ disabled: boolean }>`
  padding: 0px 1px;
  border-radius: 50px;
  display: flex;
  align-items: center;

  opacity: ${(p) => (p.disabled ? '0.4' : '1')};
`;

const styledButtonVariants = {
  primary: {
    border: `1px solid ${colorResolver['primary'].border}`,
    boxShadow: `0 0 6px 1px ${colorResolver['primary'].shadow}`,
    background: colorResolver['primary'].backgroundMain,
  },
  red: {
    border: `1px solid ${colorResolver['red'].border}`,
    boxShadow: `0 0 6px 1px ${colorResolver['red'].shadow}`,
    background: colorResolver['red'].backgroundMain,
  },
  yellow: {
    border: `1px solid ${colorResolver['yellow'].border}`,
    boxShadow: `0 0 6px 1px ${colorResolver['yellow'].shadow}`,
    background: colorResolver['yellow'].backgroundMain,
  },
  green: {
    border: `1px solid ${colorResolver['green'].border}`,
    boxShadow: `0 0 6px 1px ${colorResolver['green'].shadow}`,
    background: colorResolver['green'].backgroundMain,
  },
  blue: {
    border: `1px solid ${colorResolver['blue'].border}`,
    boxShadow: `0 0 6px 1px ${colorResolver['blue'].shadow}`,
    background: colorResolver['blue'].backgroundMain,
  },
};

const StyledButton = styled(motion.button)<{
  size: 'small' | 'large';
}>`
  height: 100%;
  width: 100%;
  will-change: transform;
  transition: 0.1s linear transform;
  text-transform: capitalize;
  border-radius: 50px;
  padding: 7px 15px;
  color: white;
  font-size: ${(p) => (p.size === 'small' ? '15px' : '20px')};

  &:disabled {
    opacity: 0.4;
  }
`;

const buttonPressAnimationVariants = {
  pressed: { transform: 'translate3d(0px, 0px, 0px)' },
  notPressed: { transform: 'translate3d(0px, -5px, 0px)' },
};

const ButtonBase: FC<ButtonBaseProps> = ({
  className = '',
  label = '',
  color = 'primary',
  disabled = false,
  size = 'small',
  onClick = () => {},
}) => {
  const [isPressed, setIsPressed] = useState(false);
  return (
    <StyledButtonContainer
      disabled={disabled}
      className={className}
      variants={styledButtonContainerVariants}
      animate={color}
    >
      <StyledButton
        size={size}
        animate={[isPressed ? 'pressed' : 'notPressed', color]}
        variants={{
          ...buttonPressAnimationVariants,
          ...styledButtonVariants,
        }}
        onTouchStart={() => !disabled && setIsPressed(true)}
        onTouchEnd={(e) => {
          if (disabled) {
            return;
          }
          setIsPressed(false);
          onClick(e);
        }}
        disabled={disabled}
      >
        {label}
      </StyledButton>
    </StyledButtonContainer>
  );
};

export default ButtonBase;
