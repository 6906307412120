import styled from 'styled-components';
import background from './background.jpg';

const BackgroundContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: url('${background}') no-repeat center / cover;
`;

export default BackgroundContainer;
