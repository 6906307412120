import { createContext, useContext } from 'react';
import { GameObject, getPlayer, GamePhase } from '@magicyard/magicpoker-game/src/Game';

export type GameContextType = GameObject;

const GameContext = createContext<GameContextType | null>(null);

/**
 * Get the game context
 * @throws if game context is not found
 */
export const useGameContext = (): GameContextType => {
  const game = useContext(GameContext);
  if (!game) throw new Error('Game context not found');
  return game;
};

export const usePlayerContext = () => {
  const {
    G,
    ctx: { currentPlayer },
    playerID,
  } = useGameContext();

  const player = playerID !== null ? getPlayer(G, +playerID) : null;

  return {
    player,
    isTurn: currentPlayer === playerID,
    playerID,
  };
};

export default GameContext;

export { GamePhase, getPlayer };
