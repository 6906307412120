import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './css/global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import type {} from 'styled-components/cssprop';

const urlParams = new URLSearchParams(window.location.search);
const playerID = urlParams.get('playerID') || '0';
const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID || 'default';

const root = document.getElementById('root');

if (!root) {
  throw new Error('Root element not found');
}

root.addEventListener('contextmenu', function (e) {
  // stop long touch hold from poping up context menus
  e.preventDefault();
});

ReactDOM.render(
  <React.StrictMode>
    <App playerID={playerID} matchID={matchID} />
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
