import React, { FC } from 'react';
import Button, { ButtonBaseProps } from './ButtonBase';

export type AllInProps = ButtonBaseProps;

const AllIn: FC<AllInProps> = ({ ...props }) => {
  return <Button label="All in" {...props} />;
};

export default AllIn;
