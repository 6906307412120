import { NewStack } from '@magicyard/magicpoker-components';
import stackChips from './stack-chips.webp';

export type StackProps = {
  amount: number;
};

const Stack = ({ amount }) => {
  return <NewStack chipURL={stackChips} amount={amount} />;
};

export default Stack;
