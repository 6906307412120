import React from 'react';
import styled from 'styled-components';
import { useSliderContext } from './SliderContext';
import useControlsTouchHandler from './useControlsTouchHandler';

const SliderPlusContainer = styled.div`
  border-left: 1px solid #ffffff;
  font-size: 1.55rem;
  width: 15%;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 20px;
`;

const SliderPlusMagnifier = styled.div<{ show: boolean }>`
  position: absolute;
  bottom: 180%;
  color: white;
  background: #2a312f;
  border: 2px solid white;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  visibility: ${(p) => (p.show ? 'visible' : 'hidden')};
  transform: translate3d(20%, 0, 0) scale(2);
`;

const SliderPlusControl: React.FC = () => {
  const { onPlusTouchStart, onPlusTouchEnd, onPlusLongPress, longPressThresholdMS } = useSliderContext();

  const { bind, isTouchStart } = useControlsTouchHandler(
    onPlusTouchStart,
    onPlusTouchEnd,
    onPlusLongPress,
    longPressThresholdMS
  );
  return (
    <SliderPlusContainer {...bind}>
      +<SliderPlusMagnifier show={isTouchStart}>+</SliderPlusMagnifier>
    </SliderPlusContainer>
  );
};

export default SliderPlusControl;
