export enum CardValue {
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
  Nine = 9,
  Ten = 10,
  Jack = 11,
  Queen = 12,
  King = 13,
  Ace = 14,
}

export enum CardSuit {
  Clubs = 0,
  Diamonds,
  Hearts,
  Spades,
}

export interface ICard {
  value: CardValue;
  suit: CardSuit;
  humanReadableName: string;
  toString: any;
  fileName: string;
}

export const Card = (value: CardValue, suit: CardSuit): ICard => {
  return {
    value,
    suit,
    humanReadableName: getFormattedName(value, suit),
    fileName: `${value}${CardSuit[suit][0]}`,
  };
};

function getFormattedValue(value: CardValue): string {
  let valComponent = '';
  if (value == CardValue.Jack) valComponent = 'J';
  else if (value == CardValue.Queen) valComponent = 'Q';
  else if (value == CardValue.King) valComponent = 'K';
  else if (value == CardValue.Ace) valComponent = 'A';
  else valComponent = String(value);
  return valComponent;
}

function getFormattedSuit(suit: CardSuit): string {
  let suitComponent = '';
  if (suit == CardSuit.Clubs) suitComponent = '\u2663';
  else if (suit == CardSuit.Diamonds) suitComponent = '\u2666';
  else if (suit == CardSuit.Hearts) suitComponent = '\u2665';
  else if (suit == CardSuit.Spades) suitComponent = '\u2660';
  return suitComponent;
}

function getFormattedName(value: CardValue, suit: CardSuit): string {
  return getFormattedValue(value) + getFormattedSuit(suit);
}
