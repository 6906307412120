import pick from 'lodash/pick';
import { TouchEventHandler, useCallback, useState } from 'react';
import { LongPressCallback, LongPressDetectEvents, useLongPress } from 'use-long-press';

export type TouchHandlerReturn = {
  bind: {
    onTouchStart: TouchEventHandler<Element>;
    onTouchEnd: TouchEventHandler<Element>;
  };
  isTouchStart: boolean;
};

export type TouchHandler = (
  onTouchStart: LongPressCallback<Element>,
  onTouchEnd: LongPressCallback<Element>,
  onLongPress: LongPressCallback<Element>,
  longPressThreshold: number
) => TouchHandlerReturn;

const useControlsTouchHandler: TouchHandler = (onTouchStart, onTouchEnd, onLongPress, longPressThreshold) => {
  const [isTouchStart, setIsTouchStart] = useState(false);
  const callback = useCallback(
    (e) => {
      onLongPress(e);
    },
    [onLongPress]
  );
  const bind = useLongPress(callback, {
    onStart: (e) => {
      if (e?.type === 'touchstart') {
        setIsTouchStart(true);
        onTouchStart(e);
      }
    },
    onFinish: (e) => {
      setIsTouchStart(false);
      onTouchEnd(e);
    },
    onCancel: (e) => {
      setIsTouchStart(false);
      if (e?.type === 'touchend') {
        onTouchEnd(e);
      }
    },
    threshold: longPressThreshold,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
  });

  return { bind: pick(bind, ['onTouchStart', 'onTouchEnd']), isTouchStart };
};

export default useControlsTouchHandler;
