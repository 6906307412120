import shuffle from 'lodash/shuffle';
import uniq from 'lodash/uniq';
import type { BoardProps } from 'boardgame.io/react';
import { Stage, INVALID_MOVE } from 'boardgame.io/core';
import { calculateBestHand, Deck, getWinningPlayers } from './Deck';
import { ICard } from './Cards';
import { compareHands, IHand } from './Hand';
import { EffectsPlugin } from 'bgio-effects/plugin';
import { config, Effects } from './effectsConfig';
import { Game, Ctx, Move, PartialGameState } from 'boardgame.io';
import { mixpanelServer } from '@magicyard/magicpoker-analytics';
import { EventsAPI } from 'boardgame.io/dist/types/src/plugins/plugin-events';

export interface PokerState {
  matchID?: string; // This is only set after the game is created
  deck: ICard[];
  dealer: number;
  smallBlind: number;
  bigBlind: number;
  table: {
    cards: ICard[];
    pot: number;
    pots: IPot[];
  };
  players: IPlayer[];
  exitedPlayers: IPlayer[];
  playerAtSeat: Array<number | null>;
  shouldEnd: boolean;
  highestBet: number;
  minRaise: number;
  nextPos: number;
  everyoneFolded: boolean;
  winnerIDs: number[];
  winnerStackByID: { [winnerID: string]: number };
  gameLog: string[];
  SMALL_BLIND_VALUE: number;
  BIG_BLIND_VALUE: number;
  rebuysMade: number;
  maxRebuyPerPlayer: number;
}

export interface G extends PokerState {}
export type GameObject = BoardProps<PokerState>;

export type PokerCtx = Ctx & { effects?: any };
export type PokerMove = Move<PokerState, PokerCtx>;

export enum LastMove {
  fold = 'FOLD',
  check = 'CHECK',
  call = 'CALL',
  raise = 'RAISE',
  bet = 'BET',
  allIn = 'ALL IN',
  none = 'NONE',
}

export interface IPlayer {
  id: number;
  controller_id: string | undefined;
  name: string;
  avatarUrl: string;
  active: boolean;
  folded: boolean;
  stack: number;
  rebuyAvailable: boolean;
  rebuys: number;
  currentBet: number;
  totalInvested: number;
  allIn: boolean;
  cards: [ICard, ICard] | [null, null];
  amountToCall: number;
  didSpeak: boolean;
  lastMove: LastMove;
  bestHand: IHand | null;
  cardsOpen: boolean;
  isBot: boolean;
}

export enum GamePhase {
  Hand = 'hand',
  AnnounceWinners = 'announceWinners',
}

const SMALL_BLIND_VALUE = 5;
const BIG_BLIND_VALUE = SMALL_BLIND_VALUE * 2;
const STARTING_STACK = SMALL_BLIND_VALUE * 200;
const maxRebuyPerPlayer = 5;
const rebuysMade = 0;

export const getPlayer = (G: { players: IPlayer[] }, playerID: number): IPlayer => {
  const player = G.players.find((p) => p.id == playerID);
  if (!player) {
    throw new Error(`Player with the ID ${playerID} not found`);
  }
  return player;
};

const everyoneSpoke = (players: IPlayer[]): boolean => {
  const unSpokenPlayers = getActivePlayers({ players }).filter((player) => !player.didSpeak && !player.allIn);
  return unSpokenPlayers.length === 0;
};

const fold: PokerMove = (G, ctx): void => {
  if (!ctx.playerID) {
    return;
  }
  const player = getPlayer(G, +ctx.playerID);
  console.debug(`Game (${G.matchID}, ${ctx.playerID}, ${player.name}): player folds`);
  ctx.effects[Effects.Fold]();

  player.folded = true;
  player.active = false;
  player.didSpeak = true;
  player.lastMove = LastMove.fold;
};

const rebuy: PokerMove = (G, ctx) => {
  if (!ctx.playerID) {
    return;
  }
  const player: IPlayer = getPlayer(G, +ctx.playerID);

  if (player.stack > 0) return INVALID_MOVE;

  player.stack += STARTING_STACK;
  // Update rebuyAvailable option
  player.rebuyAvailable = false;
  player.rebuys += 1;
  G.rebuysMade += 1;

  return;
};

const leaveMatch: PokerMove = (G, ctx) => {
  if (!ctx.playerID) {
    return;
  }
  const player: IPlayer = getPlayer(G, +ctx.playerID);
  if (!(player && player.id)) {
    console.warn(`Game (${G.matchID}): Player ${ctx.playerID} not in G.playes, probably already left`);
    return;
  }
  G.players = G.players.filter((p: IPlayer) => p.id !== player.id);
  G.exitedPlayers.push(player);

  const seat = G.playerAtSeat.indexOf(player.id);
  G.playerAtSeat[seat] = null;

  G.gameLog.push(`${player.name} Left the game`);

  G.nextPos = getNextActiveSeat(G, ctx.playOrderPos + 1);
};

const joinMatch: PokerMove = (G, ctx: PokerCtx, player?: IPlayer) => {
  player = player || G.exitedPlayers.pop();

  if (!player) {
    throw new Error('No player to join');
  }

  // reset player
  player.active = false;
  player.stack = STARTING_STACK;
  player.cards = [null, null];
  player.currentBet = 0;
  player.totalInvested = 0;

  G.players = [...G.players, player];
  const emptySeat = G.playerAtSeat.indexOf(null);
  G.playerAtSeat[emptySeat] = player.id;

  G.gameLog.push(`${player.name} Has come back from the dead`);

  G.nextPos = getNextActiveSeat(G, ctx.playOrderPos + 1);

  return;
};

const putMoneyIn = (G: PokerState, player: IPlayer, amount: number) => {
  if (player.stack < amount) {
    return INVALID_MOVE;
  }

  player.stack -= amount;
  player.currentBet += amount;
  player.totalInvested += amount;
  // G.table.pot += amount; This is taken care of after calculatePots

  // i.e raise or big blind
  if (player.currentBet > G.highestBet) {
    G.highestBet = player.currentBet;
    G.minRaise = Math.max(BIG_BLIND_VALUE, player.currentBet - player.amountToCall);

    G.players.forEach((player) => {
      const maxAmountToCall = G.highestBet - player.currentBet;
      player.amountToCall = maxAmountToCall > player.stack ? player.stack : maxAmountToCall;
    });

    // All other active players should get a chance to speak
    G.players.filter(isActive).forEach((player) => {
      player.didSpeak = false;
    });
  }

  G.table.pots = calculatePots(G);
  // Use G.table.pot for convenience / backwards compatibility. This is the only place it should be set!
  // G.table.pots.length is always > 0 (calculatePots always return at least the default pot)
  // @ts-expect-error
  G.table.pot = G.table.pots[G.table.pots.length - 1].cumulative;

  return;
};

export interface IPot {
  highestBet: number;
  creator?: number;
  cumulative?: number;
  value?: number;
  playerIDs?: number[];
  winnerIDs?: number[];
}

const burnCard = (G: PokerState): void => {
  G.deck.pop();
};

const openCard = (G: PokerState, ctx: PokerCtx): void => {
  const card = G.deck.pop();

  if (!card) {
    throw new Error('No cards left in deck');
  }

  G.table.cards.push({ ...card });
  ctx.effects[Effects.FlipCard]({ ...card });
};
const calculatePots = (G: PokerState): IPot[] => {
  // Pots are determined by all-ins
  const sidePots = G.players
    .filter((p) => p.allIn)
    .map((p) => {
      return { highestBet: p.totalInvested, creator: p.id };
    });

  const prevSidepots = G.table.pots.slice(0, G.table.pots.length - 1); // remove main pot
  const currSidepots = sidePots;
  if (prevSidepots.length < currSidepots.length) {
    // A new sidepot was created
    // now find the new sidepot object
    const newSidepot = currSidepots.find((currSidepot) => {
      const matchingCreator = prevSidepots.find((prevSidepot) => currSidepot.creator === prevSidepot.creator);
      return !matchingCreator;
    });
    if (newSidepot) {
      // check if this is a "real" sidepot (make this nicer)
      if (
        currSidepots.find(
          (sidePot) => sidePot.highestBet > newSidepot.highestBet && sidePot.highestBet !== newSidepot.highestBet
        )
      ) {
        const sidepotCreator = G.players.find((player) => player.id === newSidepot.creator);

        if (!sidepotCreator) {
          throw new Error('Sidepot creator not found');
        }

        // the newly created sidepot adheres to game rules, add a log
        G.gameLog.push(`${sidepotCreator.name}'s all-in ($${newSidepot.highestBet}) created a side pot`);
      } else {
        console.debug(`Game (${G.matchID}): sidepot ${newSidepot.creator} was found but is not added to game log`);
      }
    } else {
      // there has to be a new sidepot, this is a bug
      console.warn(`Game (${G.matchID}): New sidepot was created but not found`);
    }
  }
  // Add the main pot
  const pots: IPot[] = [{ highestBet: Math.max(...G.players.map((p) => p.totalInvested)) }, ...sidePots];
  // sort lowest to highest
  pots.sort((a, b) => {
    let diff = a.highestBet - b.highestBet;
    if (diff === 0) {
      // Pot with no creator ("main") should be last
      diff = (a.creator !== undefined ? 0 : 1) - (b.creator !== undefined ? 0 : 1);
    }
    return diff;
  });

  for (let i = 0; i < pots.length; i++) {
    const pot = pots[i];
    let cumulative = pot.cumulative || 0;
    G.players.forEach((player) => {
      cumulative = cumulative + Math.min(player.totalInvested, pot.highestBet);
      if (player.totalInvested >= pot.highestBet) {
        pot.playerIDs = [...(pot.playerIDs || []), player.id];
      }
    });
    pot.cumulative = cumulative;
    // Since pots are in acending order, the value is the diff from previous pot
    pot.value = i > 0 ? cumulative - (pots[i - 1].cumulative || 0) : pot.cumulative;
  }

  return pots;
};

const allIn: PokerMove = (G, ctx): void => {
  ctx.effects[Effects.AllIn]();
  console.debug(`Game (${G.matchID}): player went all in`);
  if (!ctx.playerID) throw new Error('playerID is undefined');
  const player = getPlayer(G, +ctx.playerID);
  player.didSpeak = true;
  player.allIn = true;
  player.lastMove = LastMove.allIn;
  putMoneyIn(G, player, player.stack);
};

const check: PokerMove = (G, ctx): void => {
  ctx.effects[Effects.Check]();
  if (!ctx.playerID) throw new Error('playerID is undefined');
  const player = getPlayer(G, +ctx.playerID);
  player.didSpeak = true;
  player.lastMove = LastMove.check;
};

const call: PokerMove = (G, ctx): void => {
  if (!ctx.playerID) throw new Error('playerID is undefined');
  const player = getPlayer(G, +ctx.playerID);
  if (player.amountToCall === 0) {
    console.debug(`Game (${G.matchID}, ${ctx.playerID}): call(0) ==> check()`);
    return check(G, ctx);
  } else if (player.amountToCall === player.stack) {
    console.debug(`Game (${G.matchID}): call(entire stack) ==> allIn()`);
    return allIn(G, ctx);
  } else {
    ctx.effects[Effects.Call]();
  }

  putMoneyIn(G, player, player.amountToCall);
  player.didSpeak = true;
  player.lastMove = LastMove.call;
};

const raise: PokerMove = (G, ctx, addedChips: number): void | string => {
  ctx.effects[Effects.Raise]();
  if (!ctx.playerID) throw new Error('playerID is undefined');
  const player = getPlayer(G, +ctx.playerID);
  if (addedChips > player.stack) {
    return INVALID_MOVE;
  }
  if (addedChips == player.stack) {
    return allIn(G, ctx);
  }
  const raiseBy = addedChips - player.amountToCall;
  if (raiseBy < G.minRaise) {
    return INVALID_MOVE;
  }

  player.amountToCall == 0 ? (player.lastMove = LastMove.bet) : (player.lastMove = LastMove.raise);

  putMoneyIn(G, player, addedChips);
  player.didSpeak = true;

  // All other active players should get a chance to speak
  G.players
    .filter((player) => player.id.toString() !== ctx.playerID && isActive(player))
    .forEach((player) => {
      player.didSpeak = false;
    });
};

const nextHand: PokerMove = (G, ctx) => {
  endHand(G, ctx);
  console.debug(`Game (${G.matchID}): ending announce phase`);
  ensureEvents(ctx).endPhase();
};

const forceTwoWayTie: PokerMove = (G) => {
  getPlayer(G, 1).cards = [...getPlayer(G, 0).cards];
  console.debug(`Game (${G.matchID}): cloned player 0's cards to player 1`);
};

const forceThreeWayTie: PokerMove = (G) => {
  getPlayer(G, 1).cards = [...getPlayer(G, 0).cards];
  getPlayer(G, 2).cards = [...getPlayer(G, 0).cards];
  console.debug(`Game (${G.matchID}): cloned player 0's cards to player 1 and 2`);
};

export const GAME_ID = 'poker';

type SetupData = {
  players: Array<{
    name: string;
    avatarUrl: string;
    controller_id: string;
    isBot?: boolean;
  }>;
};

export const Poker: Game<PokerState, PokerCtx, SetupData> = {
  name: GAME_ID,
  plugins: [EffectsPlugin(config)],
  setup: (ctx, setupData) => {
    const dealer = 0;
    const smallBlind = dealer + 1;
    const bigBlind = dealer + 2;
    type PlayerInit = {
      name: string;
      avatarUrl: string;
      controller_id: string;
      isBot?: boolean;
    } | null;
    const initPlayers: PlayerInit[] = setupData?.players || Array(ctx.numPlayers).fill(null);

    const players: IPlayer[] = initPlayers.map((player, index: number): IPlayer => {
      return {
        id: index,
        controller_id: player?.controller_id,
        name: player?.name || `Player ${index}`,
        avatarUrl: player?.avatarUrl || `https://i.pravatar.cc/174?${Math.random()}`,
        active: true,
        folded: false,
        stack: STARTING_STACK, // (index + 1) * 100,
        rebuys: 0,
        rebuyAvailable: false,
        currentBet: 0,
        totalInvested: 0,
        cards: [null, null],
        amountToCall: 0,
        didSpeak: false,
        allIn: false,
        lastMove: LastMove.none,
        bestHand: null,
        cardsOpen: false,
        isBot: player?.isBot || false,
      };
    });

    // playerAtSeat[i] => the playerID of player at seat i
    const playerAtSeat = shuffle([...Array(players.length).keys()]);

    const pots: IPot[] = [];

    const winnerStackByID: { [winnerID: string]: number } = {};

    players
      .filter((p) => !p.isBot)
      .forEach(({ name, id, stack, controller_id }) => {
        mixpanelServer.track('Poker Game Setup [Server]', {
          distinct_id: controller_id,
          name,
          playerID: id,
          stack,
        });
      });

    return {
      deck: Deck(),
      dealer,
      smallBlind,
      bigBlind,
      table: {
        cards: [],
        pot: 0,
        pots,
      },
      players,
      exitedPlayers: [],
      playerAtSeat,
      shouldEnd: false,
      highestBet: 0,
      minRaise: 0,
      nextPos: 0,
      everyoneFolded: false,
      winnerIDs: [],
      winnerStackByID,
      gameLog: [],
      SMALL_BLIND_VALUE,
      BIG_BLIND_VALUE,
      rebuysMade,
      maxRebuyPerPlayer,
    };
  },
  moves: {
    fold,
    call,
    raise,
    rebuy,
    nextHand,
    forceTwoWayTie,
    forceThreeWayTie,
    leaveMatch,
    joinMatch,
  },
  phases: {
    [GamePhase.Hand]: {
      start: true,
      next: GamePhase.AnnounceWinners,
      onBegin: (G, ctx) => {
        console.log(`Game (${G.matchID || 'new'}): Starting new hand`);

        // Assume dealer was already moved to the right place

        // deal cards
        G.deck = Deck();

        // First to get dealt a card
        let nextActiveSeat = getNextActiveSeat(G, G.dealer + 1);

        // Deal cards
        ctx.effects[Effects.DealCards]();
        for (let cardIndex = 0; cardIndex < 2; cardIndex++) {
          for (let playerIndex = 0; playerIndex < G.players.length; playerIndex++) {
            const playerID = G.playerAtSeat[nextActiveSeat];
            if (playerID === null) throw new Error('playerID is expected to be defined');
            const player = getPlayer(G, playerID);
            const card = G.deck.pop();
            if (!card) throw new Error('card is expected to be defined');
            player.cards[cardIndex] = card;
            nextActiveSeat = getNextActiveSeat(G, nextActiveSeat + 1);
          }
        }

        // Ensure legal indeces
        G.smallBlind = getNextActiveSeat(G, G.smallBlind);
        G.bigBlind = getNextActiveSeat(G, G.bigBlind);

        // Set up blinds. TODO: not hardcoded, changes with time.
        const smallBlindPlayerId = G.playerAtSeat[G.smallBlind];
        if (smallBlindPlayerId === null) throw new Error('smallBlindPlayerId is expected to be defined');
        const smallBlindPlayer = getPlayer(G, smallBlindPlayerId);
        const bigBlindPlayerId = G.playerAtSeat[G.bigBlind];
        if (bigBlindPlayerId === null) throw new Error('bigBlindPlayerId is expected to be defined');
        const bigBlindPlayer = getPlayer(G, bigBlindPlayerId);
        putMoneyIn(G, smallBlindPlayer, SMALL_BLIND_VALUE);
        putMoneyIn(G, bigBlindPlayer, BIG_BLIND_VALUE);
        console.debug(`Game (${G.matchID || 'new'}): New hand setup complete`);
        G.players
          .filter((p) => !p.isBot)
          .forEach(({ name, id, controller_id, stack, cards }) => {
            mixpanelServer.track('Poker Hand Started [Server]', {
              distinct_id: controller_id,
              playerID: id,
              matchID: G.matchID,
              name,
              stack,
              cards,
            });
          });
      },
      onEnd: (G, ctx) => {
        console.debug(`Game (${G.matchID}): Ending Hand phase`);
      },
      turn: {
        activePlayers: {
          currentPlayer: { stage: 'playing', maxMoves: 1 },
          others: { stage: 'notPlaying' },
        },
        stages: {
          playing: {
            moves: {
              raise,
              fold,
              call,
              leaveMatch,
              joinMatch,
              rebuy,
            },
          },
          notPlaying: {
            moves: { rebuy, leaveMatch, joinMatch },
          },
        },
        maxMoves: 1,
        order: {
          // pre-flop betting starts to the left of big blind
          first: (G, ctx) => {
            return getNextActiveSeat(G, G.bigBlind + 1);
          },

          // Get the next value of ctx.playOrderPos.
          // This is called at the end of each turn.
          // The phase ends if this returns undefined.
          next: (G, ctx) => {
            // G.nexPos will be updated onMove()
            console.debug(`Game (${G.matchID}):`, { nextPos: G.nextPos });
            return G.nextPos;
          },

          // OPTIONAL:
          // Override the initial value of playOrder.
          // This is called at the beginning of the game / phase.
          playOrder: (G, ctx) => {
            // This has null values in it, but G.nextPos makes sure they're never accessed.
            return G.playerAtSeat.map((id) => `${id}`);
          },
        },
        // Called at the end of each move.
        onMove: (G, ctx) => {
          const activePlayers = getActivePlayers(G);
          if (activePlayers.length === 1) {
            // Everyone folded, we have a winner
            G.everyoneFolded = true;

            // If only one player remaining he wins all the pots
            G.table.pots.forEach((pot: IPot) => {
              pot.winnerIDs = [activePlayers[0].id];
            });

            // Must use IDs because copy on write
            const { winnerIDs } = G.table.pots[G.table.pots.length - 1];
            if (!winnerIDs) throw new Error('winnerIDs is expected to be defined');
            G.winnerIDs = winnerIDs;

            endBettingRound(G, ctx);
            ensureEvents(ctx).endPhase();
            return;
          }

          if (everyoneSpoke(G.players)) {
            // If all community cards open, showdown time
            if (G.table.cards.length === 5) {
              calculateWinners(activePlayers, G);

              console.debug(`Game (${G.matchID}): ending hand phase, begin showdown`);

              // Appears in both if and else clauses because of endPhase()
              endBettingRound(G, ctx);
              ensureEvents(ctx).endPhase();
              return;
            }
            // This is the case where all but one player is all in
            else if (activePlayers.filter((p) => !p.allIn).length <= 1) {
              // Flip all active players' cards
              activePlayers.forEach((player: IPlayer) => {
                player.cardsOpen = true;
              });

              // Open remaining community cards. TODO: Animate this:
              while (G.table.cards.length < 5) {
                burnCard(G);
                openCard(G, ctx);
              }

              calculateWinners(activePlayers, G);

              endBettingRound(G, ctx);
              ensureEvents(ctx).endPhase();
              return;
            }
            // Otherwise open next community card
            else {
              endBettingRound(G, ctx);

              if (G.table.cards.length == 0) {
                G.deck.pop(); // burn a card
                openCard(G, ctx);
                openCard(G, ctx);
                openCard(G, ctx);
              } else if (G.table.cards.length > 2) {
                G.deck.pop(); // burn a card
                openCard(G, ctx);
              }

              // This used to be inside endBettingRound but this is the only place that it's necessary, and break things when its included.
              G.nextPos = getNextActiveSeat(G, G.smallBlind);
            }
          }
          // We're in the middle of the betting round
          else {
            G.nextPos = getNextActiveSeat(G, ctx.playOrderPos + 1);
          }
        },
      },
    },
    [GamePhase.AnnounceWinners]: {
      next: GamePhase.Hand,
      onBegin: (G, ctx) => {
        G.players
          .filter((p) => !p.isBot)
          .forEach(
            ({ name, id, controller_id, stack, active, rebuys, totalInvested, folded, allIn, bestHand, cardsOpen }) => {
              mixpanelServer.track('Poker Hand Ended [Server]', {
                distinct_id: controller_id,
                matchID: G.matchID,
                playerID: id,
                name,
                stack,
                active,
                rebuys,
                totalInvested,
                folded,
                allIn,
                bestHand,
                cardsOpen,
              });
            }
          );
      },
      turn: {
        activePlayers: {
          all: Stage.NULL,
        },
        onBegin: (G, ctx) => {
          handleWinners(G, ctx);
          roundEndShowCards(G);
        },
      },
      moves: {
        nextHand,
        rebuy,
        leaveMatch,
        joinMatch,
      },
    },
  },
};

const isActive = (player: IPlayer): boolean => player && player.active; // get rid of 'active' and have it always be calculated

const getActivePlayers = ({ players }: { players: IPlayer[] }): IPlayer[] =>
  players.filter((player: IPlayer) => isActive(player));

const getNextActiveSeat = (G: PokerState, nextPos: number) => {
  // IMPORTANT! This has to be the same as max ctx.playOrderPos (default: ctx.numPlayers)
  const numSeats = G.playerAtSeat.length; // === ctx.numPlayers;
  nextPos = nextPos % numSeats;
  let nextPlayerId = G.playerAtSeat[nextPos];
  let nextPlayer = nextPlayerId !== null ? getPlayer(G, nextPlayerId) : null;
  // Skip inactive players
  let remainingSkips = numSeats;
  while (remainingSkips && nextPlayer !== null && (!isActive(nextPlayer) || nextPlayer.allIn)) {
    nextPos = (nextPos + 1) % numSeats;
    nextPlayerId = G.playerAtSeat[nextPos];
    nextPlayer = nextPlayerId !== null ? getPlayer(G, nextPlayerId) : null;
    remainingSkips--;
  }
  if (remainingSkips == 0) {
    console.error(`Game (${G.matchID}): CRITICAL: getNextActivePos() went on forever!!!`);
  }
  return nextPos;
};

const isRebuyAvailable = (G: PokerState, ctx: PokerCtx, player: IPlayer): boolean => {
  return (
    player.stack === 0 &&
    (ctx.phase === GamePhase.AnnounceWinners || (ctx.phase === GamePhase.Hand && player.totalInvested === 0)) &&
    player.rebuys <= G.maxRebuyPerPlayer
  );
};

const isHuman = (player: IPlayer): boolean => !player.isBot; // get rid of 'active' and have it always be calculated

export const countHumanPlayers = ({ players }: { players: IPlayer[] }): number =>
  players.filter((player: IPlayer) => isHuman(player)).length;

const endBettingRound = (G: PokerState, ctx: PokerCtx) => {
  // End of betting round
  ctx.effects[Effects.ChipsToMainPot]();
  console.debug(`Game (${G.matchID}) End betting round`);

  // reset round-related properties
  G.players.forEach((player) => {
    player.lastMove = LastMove.none;
    player.currentBet = 0;
    player.amountToCall = 0;
    player.didSpeak = false;
    player.rebuyAvailable = isRebuyAvailable(G, ctx, player);
  });

  G.highestBet = 0;
  G.minRaise = BIG_BLIND_VALUE;
};

const makeLogLine = (winner: IPlayer, amount: number, everyoneFolded?: boolean): string => {
  const winAmount = amount - winner.totalInvested;
  let line = winAmount > 0 ? `${winner.name} won $${winAmount}` : `${winner.name} gets $${amount} back`;

  if (everyoneFolded) {
    line += '!';
  } else {
    const { bestHand } = winner;
    if (!bestHand) throw new Error(`No best hand for ${winner.name}`);
    line += ` with ${bestHand.cards.map((c) => c.humanReadableName).join(' ')} ${bestHand.name}!`;
  }

  return line;
};

const handleWinners = (G: PokerState, ctx: PokerCtx) => {
  const { pots } = G.table;
  G.gameLog = [];

  let winningsByPlayerID: { [playerID: string]: number } = {};

  pots.forEach((pot) => {
    const { winnerIDs, value } = pot;
    if (!(winnerIDs !== undefined && winnerIDs?.length > 0)) throw new Error(`No winnerIDs for pot ${pot}`);
    if (value === undefined) throw new Error(`No value for pot ${pot}`);
    const potWinnings = winnerIDs.length > 1 ? Math.floor(value / winnerIDs.length) : value;
    winnerIDs.forEach((id) => {
      if (id in winningsByPlayerID) {
        winningsByPlayerID[id] += potWinnings;
      } else {
        winningsByPlayerID[id] = potWinnings;
      }
    });
  });

  // Pots with only one player are not "won", they are refunded.
  const multiPlayerPots: IPot[] = pots.filter((pot: IPot) => pot.playerIDs && pot.playerIDs.length > 1);

  const activePlayers = getActivePlayers(G);
  if (activePlayers.length > 1) {
    G.winnerIDs = uniq(
      multiPlayerPots
        .filter((p): p is IPot & { winnerIDs: number[] } => Boolean(p.winnerIDs))
        .flatMap((p) => p.winnerIDs)
    );
  }

  // Distribute winnings and log winners
  Object.entries(winningsByPlayerID).forEach(([playerID, value]) => {
    const winner = getPlayer(G, Number(playerID));

    if (G.winnerIDs.includes(winner.id)) {
      G.gameLog.push(makeLogLine(winner, value, G.everyoneFolded));
    }
    winner.stack += value;
    G.winnerStackByID[winner.id] = G.winnerStackByID[winner.id] || 0 + value;
  });

  // Update rebuyAvailable
  G.players.forEach((player) => {
    player.rebuyAvailable = isRebuyAvailable(G, ctx, player);
  });

  // TODO: handle main and side pot remainders
  // const remainder = pot.value % winnerIDs.length;
  // G.table.pot = remainder;
};

const roundEndShowCards = (G: PokerState): void => {
  if (G.everyoneFolded === false) {
    G.players.forEach((player) => {
      player.cardsOpen = true;
    });
  }
};

const endHand = (G: PokerState, ctx: PokerCtx): void => {
  //clear cards
  G.players.forEach((player: IPlayer) => {
    player.cards = [null, null];
    player.bestHand = null;
    player.allIn = false;
    player.folded = false;
    player.active = player.stack > 0; // TODO: Allow sit out;
    player.cardsOpen = false;
    player.totalInvested = 0;
  });

  G.everyoneFolded = false;

  // Clear table
  G.table.cards = [];

  G.winnerStackByID = {};
  // Clear winners
  G.winnerIDs = [];

  // TODO: In some cases blinds do move to inactive players
  G.dealer = getNextActiveSeat(G, G.dealer + 1);
  // Notice, G.dealer has been updated
  G.smallBlind = getNextActiveSeat(G, G.dealer + 1);
  G.bigBlind = getNextActiveSeat(G, G.smallBlind + 1);
};

const calculateWinners = (activePlayers: IPlayer[], G: PokerState): void => {
  activePlayers.forEach((player) => {
    if (!player.cards[0]) throw new Error(`No cards for ${player.name}`);
    player.bestHand = calculateBestHand(player.cards, G.table.cards);
  });
  // ranking all active players hands
  const playerRankingByHand = [...activePlayers].sort((playerA, playerB) =>
    playerA.bestHand && playerB.bestHand ? compareHands(playerA.bestHand, playerB.bestHand) : 0
  );
  playerRankingByHand.reverse();

  G.table.pots.forEach((pot: IPot) => {
    const potPlayerRankings = playerRankingByHand.filter((player) => pot.playerIDs?.includes(player.id));
    pot.winnerIDs = getWinningPlayers(potPlayerRankings).map((p) => p.id);
  });

  // Must use IDs because copy on write
  // @ts-expect-error
  G.winnerIDs = G.table.pots[G.table.pots.length - 1].winnerIDs;
};

function ensureEvents(ctx: PokerCtx): EventsAPI {
  if (!ctx.events) throw new Error('Events API not initialized');
  return ctx.events;
}
