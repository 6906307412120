import React from 'react';
import { LongPressCallback } from 'use-long-press';

export type SliderContextType = {
  amount: number;
  onPlusTouchStart: LongPressCallback<Element>;
  onPlusTouchEnd: LongPressCallback<Element>;
  onPlusLongPress: LongPressCallback<Element>;
  onMinusTouchStart: LongPressCallback<Element>;
  onMinusTouchEnd: LongPressCallback<Element>;
  onMinusLongPress: LongPressCallback<Element>;
  longPressThresholdMS: number;
  longPressKeyRepeatMS: number;
};

export const SliderContext = React.createContext<SliderContextType | null>(null);

export const useSliderContext = () => {
  const slider = React.useContext(SliderContext);
  if (!slider) throw new Error('useSliderContext must be used within a SliderContextProvider');
  return slider;
};
