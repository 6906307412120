import NodeMixpanel from 'mixpanel';
import clientMixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN = '2086bada501bab933572b6f6905eb3bc';

const initServerMixpanel = () => {
  return NodeMixpanel.init(MIXPANEL_TOKEN, {
    protocol: 'https',
    debug: false,
    host: 'mp.magicpoker.app',
  });
};

const initClientMixpanel = () => {
  clientMixpanel.init(MIXPANEL_TOKEN, {
    debug: false,
    api_host: 'https://mp.magicpoker.app',
  });
  return clientMixpanel;
};

// @ts-ignore
const isBrowser = typeof window !== 'undefined';

const _mixpanelServer = initServerMixpanel();
const _mixpanelClient = initClientMixpanel();

const mixpanelServer = isBrowser
  ? new Proxy(_mixpanelServer, {
      get() {
        return () => {};
      },
    })
  : initServerMixpanel();
const mixpanelClient = isBrowser
  ? initClientMixpanel()
  : new Proxy(_mixpanelClient, {
      get() {
        return () => {};
      },
    });

export { mixpanelClient, mixpanelServer };
