import { FC } from 'react';
import Button, { ButtonBaseProps } from './ButtonBase';

export type CallProps = ButtonBaseProps;

const Call: FC<CallProps> = ({ ...props }) => {
  return <Button color="green" {...props} />;
};

export default Call;
