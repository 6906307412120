import { GameObject } from '@magicyard/magicpoker-game/src/Game';
import GameContext from 'context/GameContext';
import Main from 'Main';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
import type { FC } from 'react';

import { LazyMotion, domAnimation } from 'framer-motion';
import { InitializedMixpanel } from 'context/MixpanelContext';

const Board: FC<GameObject> = (props) => {
  return (
    <InitializedMixpanel>
      <LazyMotion features={domAnimation}>
        <GameContext.Provider value={props}>
          <ThemeProvider theme={theme}>
            <Main />
          </ThemeProvider>
        </GameContext.Provider>
      </LazyMotion>
    </InitializedMixpanel>
  );
};

export default Board;
