import _ from 'lodash';
import { ICard, Card } from './Cards';
import { IHand, calculateHand, compareHands } from './Hand';
import { k_combinations } from './utils';

import { IPlayer } from './Game';

export const Deck = (): ICard[] => {
  let cards: ICard[] = [];
  for (let i: number = 2; i <= 14; i++) {
    for (let j: number = 0; j <= 3; j++) {
      let card: ICard = Card(i, j);
      cards.push(card);
    }
  }
  cards = _.shuffle(cards);
  return cards;
};

export function calculateBestHand(playerHand: [ICard, ICard], communityCards: ICard[]): IHand {
  let cardCombos: ICard[][] = k_combinations(playerHand.concat(communityCards), 5);
  let hands: IHand[] = _.map(cardCombos, (cards) => {
    return calculateHand(cards);
  });
  hands.sort(compareHands);
  return hands[hands.length - 1];
}

export function bestHandCardsLocation(winner: IPlayer, communityCards: ICard[], everyoneFolded: boolean) {
  const defaultRet = {
    communityWinningCardsLocation: [0, 1, 2, 3, 4],
    handWinningCardsLocation: [],
  };
  if (everyoneFolded) {
    return defaultRet;
  }

  let communityWinningCardsLocation: number[] = [];
  let handWinningCardsLocation: number[] = [];

  if (winner.cards[0] === null) throw new Error('Expected cards to be defined');

  let winnerBestHand = calculateBestHand(winner.cards, communityCards);
  if (!winnerBestHand) {
    return defaultRet;
  }
  communityCards.forEach((card, i) => {
    if (winnerBestHand.cards.includes(card)) {
      communityWinningCardsLocation.push(i);
    }
  });

  winner.cards.forEach((card, i) => {
    if (winnerBestHand.cards.includes(card)) {
      handWinningCardsLocation.push(i);
    }
  });

  return { communityWinningCardsLocation, handWinningCardsLocation };
}

export function getWinningPlayers(rankedPlayers: IPlayer[]) {
  let winners = [rankedPlayers[0]];
  //As long as the runner-up matches the best hand, they will split the pot.
  for (let i = 0; i < rankedPlayers.length - 1; i++) {
    const rankedPlayer = rankedPlayers[i];
    const nextRankedPlayer = rankedPlayers[i + 1];
    if (!rankedPlayer.bestHand) throw new Error('Expected bestHand to be defined');
    if (!nextRankedPlayer.bestHand) throw new Error('Expected bestHand to be defined');
    if (compareHands(rankedPlayer.bestHand, nextRankedPlayer.bestHand) === 0) {
      winners.push(nextRankedPlayer);
    } else {
      break;
    }
  }
  return winners;
}
