import { Client } from 'boardgame.io/react';
import { SocketIO } from 'boardgame.io/multiplayer';
import Board from './Board';
import { Poker } from '@magicyard/magicpoker-game/src/Game';
import BackgroundContainer from './components/BackgroundContainer';

const urlParams = new URLSearchParams(window.location.search);
const serverURL = urlParams.get('serverURL') || process.env.REACT_APP_GAME_START_SERVER_URL;

const getMultiplayerMode = () => {
  console.log(`Running using SocketIO() multiplayer with server: ${serverURL}`);
  return SocketIO({ server: serverURL });
};

const App = Client({
  game: Poker,
  numPlayers: 6,
  board: Board,
  loading: () => (
    <BackgroundContainer>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        Loading...
      </div>
    </BackgroundContainer>
  ),
  debug: !window.location.search.includes('hidePanel'),
  multiplayer: getMultiplayerMode(),
});

export default App;
