import styled from 'styled-components';

export type TurnLabelProps = {
  active: boolean;
  className?: string;
};

const TurnLabelStyle = styled.div<{ active: boolean }>`
  visibility: ${(p) => (p.active ? 'visible' : 'hidden')};
  text-transform: capitalize;
  background: #ad1918;
  border-radius: 50px;
  box-shadow: 0 0 2px 2px #08362e inset;
  border: 1px solid #ffffff;
  padding: 10px 30px;
  color: white;
  box-shadow: 0px 0px 25px 15px white;
`;

const TurnLabel = ({ active, className = '' }) => {
  return (
    <TurnLabelStyle className={className} active={active}>
      Your Turn!
    </TurnLabelStyle>
  );
};

export default TurnLabel;
